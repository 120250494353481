import c from "classnames";
import { h, falIcon } from "h";
import validator from "../util/validator";
import {
  form,
  ManagedForm,
  NativeCheckboxField,
  SubmitButton
} from "../form";

const onClick = url => () => window.open(url);
const TosForm = ({ fields, form, onSubmit }) => {
  return h(ManagedForm, { form, className: "tos", onSubmit }, [
    h(GreyPanel, { classes: ["tos-link"], onClick: onClick("/tos") }, [
      h(".lhs", [
        h("span", "Read our Terms of Service")
      ]),
      h(".rhs",
        falIcon("angle-right")
      )
    ]),
    h(GreyPanel, { classes: ["tos-link"], onClick: onClick("/privacy") }, [
      h(".lhs", [
        h("span", "Read our Privacy Policy")
      ]),
      h(".rhs",
        falIcon("angle-right")
      )
    ]),
    h("div.data-sharing", [
      h("h3", "Data Sharing with Third-Parties"),
      h("p", `Some elements of your personal data are contractually required to
      be shared with the following third-parties in order to provide service:`)
    ]),
    h(GreyPanel, { header: "Processing Payments" }, [
      h("p",
        `Hover requires you share your full name, address, and payment
        information with Braintree (a service of PayPal) and Kount for the
        purposes of processing payment.`
      )
    ]),
    h(GreyPanel, { header: "Sending Emails" }, [
      h("p",
        `Hover requires you share your email address with Mandrill (a service of MailChimp)
        for the purposes of sending you Hover transactional emails such as receipts,
        renewal reminders, and password reset emails. If you choose to subscribe to our email newsletter,
        we'll share your email address with Mailchimp for the purposes of sending marketing emails.`
      )
    ]),
    h(GreyPanel, { header: "Registering Domains" }, [
      h("p", [
        `Hover requires you share your first and last name, organization name,
        full address, email address, and phone number with OpenSRS or Enom
        (services of Tucows Inc.) for the purposes of registering and managing
        domain name(s). Some domain extensions require additional information
        such as citizenship or unique identifiers.`
      ])
    ]),
    h(GreyPanel, { header: "Providing Customer Support" }, [
      h("p",
        `When you interact with our customer support team, any information you
        provide may be captured and stored in Zendesk.`
      )
    ]),
    h(".fieldgroup.buttons.vertical", [
      h(NativeCheckboxField, {
        field: fields.consent,
        label: [
          "I have read and agree to the ",
          h("a", { href: "/tos", target: "_blank" }, "Terms of Service")
        ]
      }),
      h(NativeCheckboxField, {
        field: fields.newsletter,
        label: [
          "I would like to receive email newsletters"
        ]
      }),
      h("div", {
        className: "newsletter-text"
      }, "We send newsletters once a month with domain discounts, news and tips."),
    ]),
    h(".button-container", [
      h(SubmitButton, { form, text: "Continue" })
    ])
  ]);
}

const fields = ["consent", "newsletter"];

function validate(values) {
  return validator(values, {
    consent: {
      presence: {
        message: "Required"
      },
      inclusion: {
        within: [true],
        message: "Required"
      }
    }
  })
}

export default form(fields, validate)(TosForm);

const GreyPanel = ({ header, children, classes = [], onClick }) => {
  const className = c("grey-panel", ...classes);
  return h("div", { className, onClick }, [
    header && h("p.header", header),
    children
  ]);
}
