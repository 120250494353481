import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";

export default function ReCaptchaWrapper(props) {
  if (!props.enabled) {
    return null;
  }

  const { recaptchaRef, recaptchaKey } = props;

  return (
    <div data-test="recaptcha">
      <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} size="invisible" />
    </div>
  );
}

ReCaptchaWrapper.propTypes = {
  enabled: PropTypes.bool.isRequired,
  recaptchaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  recaptchaKey: PropTypes.string,
};
