import React from "react";
import PropTypes from "prop-types";
import { form, ManagedForm } from "checkout/form";
import validator from "checkout/util/validator";
import VerificationCodeInput from "./VerificationCodeInput";
import VerificationCodeControls from "./VerificationCodeControls";
import VerificationMessage from "./VerificationMessage";
import VerificationError from "./VerificationError";
import VerificationHolder from "./VerificationHolder";

function VerificationCodeForm(props) {
  const { fields, form, onSubmit, onBack, onResend, formError, email, onSkip, className="" } = props;
  return (
    <ManagedForm form={form} onSubmit={onSubmit} className={className}>
      <VerificationHolder>
        <VerificationError formError={formError} />
        <VerificationMessage email={email} />
        <VerificationCodeInput updateCode={fields.code.onChange} />
      </VerificationHolder>
      <VerificationCodeControls form={form} onBack={onBack} onResend={onResend} />
    </ManagedForm>
  );
}

VerificationCodeForm.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export const fields = ["code"];

export function validate(values) {
  return validator(values, {
    code: {
      verificationCode: true,
    },
  });
}

export default form(fields, validate)(VerificationCodeForm);
